import { graphql, Link } from "gatsby"
import React from "react"
import ImageGallery from "react-image-gallery"
import urlBuilder from "@sanity/image-url"
import { AiOutlineArrowLeft } from "react-icons/ai"

import Layout from "../../components/layout"
import { mySanityClient } from "../../sanityClient"
import SEO from "../../components/SEO"
import IndexContainer from "../../components/indexContainer"
import PageTitle from "../../components/PageTitle"
import ButtonLink from "../../components/ButtonLink"
import BackgroundContainer from "../../components/containers/BackgroundContainer"
import StandardContainer from "../../components/containers/StandardContainer"

const Album = ({ data }) => {
  const { title, rawAlbumImages, date, description } = data.sanityAlbums
  const builder = urlBuilder(mySanityClient)
  // Get the url for a given source
  const urlFor = (source) => builder.image(source)

  // create an array of urls for the album
  const imageUrlArray = rawAlbumImages.map((imageData) => {
    const url = urlFor(imageData.asset.id)
      .width(700)
      .format("jpg")
      .fit("max")
      .url()

    const previewUrl = urlFor(imageData.asset.id)
      .width(200)
      .height(200)
      .format("jpg")
      .url()
    // const previewUrl = url + "?h=200" + "&w=200" + "&fm=jpg"

    const altText = imageData.altText || ""
    return {
      original: url,
      originalAlt: altText,
      thumbnail: previewUrl,
      thumbnailAlt: altText,
    }
  })

  return (
    <Layout className="text-center justify-items-center">
      <SEO title={title} />
      <StandardContainer
        props={{
          fluid: null,
          className: "p-10 relative text-primary",
        }}
      >
        <div className="grid  justify-items-start">
          <ButtonLink
            to="/gallery"
            className="flex items-center gap-x-2 uppercase tracking-wide my-5"
          >
            <AiOutlineArrowLeft /> Gallery
          </ButtonLink>
        </div>
        {/* Top Matter */}
        <div className="grid justify-stretch gap-y-2">
          <PageTitle text={title} />
          <section className=" w-2/3 justify-self-center text-center">
            <div>{date}</div>
            {description && <div className="italic mt-5">{description}</div>}
          </section>
        </div>
        <div className="grid">
          <div className=" w-full my-10 mx-5 max-w-3xl justify-self-center">
            <ImageGallery items={imageUrlArray} thumbnailPosition="top" />
          </div>
        </div>
      </StandardContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    sanityAlbums(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      description
      rawAlbumImages: _rawAlbumImages(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default Album
